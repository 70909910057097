import logo from './logo.svg';
import './App.css';

import React from "react";

function Home() {
  return (

// WEBSITE ZONE //

<html>





<body>
<div id="wrapper">
    <div class="content tinted-background">
        <h2 id="invite">You're invited to...</h2>
        <div class="title">
          <div id="bs"></div>
          <div id="ttt"></div>
        
        <h2>September 8-10</h2>
        </div>

        <div class="row rev">
        <div class="column">
          <h3><i>A special weekend getaway of plein air and dancing at a summer camp in St-Calixte, QC.</i></h3>
          <ol class="toc-list">
              <li>
                <a href="#campinfo">
                  <span>Camp Info</span>
                </a>
              </li>
              <li>
                <a href="#contribution">
                  <span>Contribute</span>
                </a>
              </li>
              <li>
                <a href="#signup">
                  <span id="upsign">Sign Up</span><span> Waitlist</span>
                </a>
              </li>
              <li>
                <a href="#schedule">
                  <span>Weekend Schedule</span>
                </a>
              </li>
          </ol>
          </div>
        <div class="column reg"><img src='closed.gif' /></div>
        </div>
      
        <img src="BSBanner1.jpg" class="imgbanner"/>


        <div id="campinfo">
        <h2>Camp Info</h2>
        <hr/>
        <div class="row info rev">
          <div class="column">
            <img id="lights-img" src="visuals.jpg"/>
            </div>
          <div class="column">
            <h3>Visuals</h3>
            <p>Once again, our crack team of visual artists will whip up something cool for the dancefloor and other spaces around the camp.</p>
            
            <p>There's plenty of space for others to participate and/or learn something new throughout the weekend.</p>
            <p>If you'd like to bring their own visual component to the camp, or take a VJ set at some point during the night, make it known in the #visual-arts Discord channel!</p>
          </div>
            </div>
        <div class="row info">
          <div class="column">
        <h3>Music</h3>
        <p>This year’s dancefloor will be enclosed by a 4-point soundsystem 💆<br/><br/>Saturday night tunes will be curated by some of our favourite mtl crews:</p>
        <ul>
          <li id="praxis"><a href="https://www.instagram.com/praxis.mtl/" target="_blank"  >PRAXIS</a></li>
          <li id="greenhouse"><a  href="https://youneedahearttolive.com" target="_blank">Greenhouse</a></li>
          <li id="flush"><a href="https://lowkey.giving/" target="_blank">Flush</a></li>
          </ul>
          <p id="oxy">There will also be an ambient chillout space away from the dancefloor, curated by <a href="https://www.instagram.com/oxygenmontreal" target="_blank"> Oxygen </a></p>
          </div>
          <div class="column"><img id="music-img" src="music-img.jpg"/></div>
          </div>
        
        <div class="row info rev">
          <div class="column sm" >
            <img id="sun-img" src="sun.gif"/>
          </div>
          <div class="column lg">
          <h3>Activities</h3>
          <p>Organized activities outside of party hours 🌞<br/><br/>As always, people are welcome to propose / organize their own workshops in the Discord.</p>
          <ul>
          <li>Capture the flag! 🚩
            <ul><li>Coloured chalk provided by certified CTF masters Darcy & Tiger.</li></ul>
          </li>
          <li>The First Ever Bohême Système Dinnertime Talent Show 👯
          <ul><li>Sign up for this friendly competition and show the camp what you’ve got! Everyone gets a prize! 🎁</li></ul>
          </li>
          <li>Campsite Decoration Contest 🏕️
          <ul><li>$200 cash to the winners with the funkiest lodging. Bring materials to adhere, drape, suspend, project on your cabin / tent!</li></ul>
          </li></ul>
          </div>
          </div>

        <div class="row info">
        <div class="column">
          <h3>Food</h3>
          <p>4 meals will be served at the large outdoor kitchen with picnic tables in the centre of the camp: Friday dinner, Saturday brunch + dinner, Sunday brunch. </p>
        <ul>
          <li>There will always be a vegan option, communicate in the Discord if you have any allergies and we will do our best to accommodate </li>
          <li>There is one fridge in the outdoor kitchen that can be used for any food that you would like to share with fellow campers. Otherwise, please try to arrange your own food/drink cooling solutions among yourselves 🥶</li>
          </ul>
          </div>
          
          <div class="column opac">
            <img id="food-img" src="food.gif"/>
          </div>
          </div>

          <div class="row info rev">
            <div class="column opac2"><img id="cabin-img" src="cabin.jpg"/></div>
            <div class="column">
          <h3>Sleeping Arrangements</h3>
          <ul>
          <li>The campsite has 21 3-bed cabins, and plenty of room to set up tents on the campground / in the woods.</li>
          <li>Cabins can be reserved in the relevant Google Doc and are first come first served, get ‘em while they’re hot 🥵🥨</li>
          </ul>

          <h3>Hygeine</h3>
          <ul>
          <li>There is a building containing 6 hot showers and flush toilets.</li>
          <li>Cooking will be done in an indoor kitchen, but there is a communal outdoor kitchen for people to snack, make coffee, or tea.</li>
          </ul>
          </div>
          </div>

          
          <div class="row info">
          <div class="column">
          <h3>Environment</h3>
          <p>The region of St Calixte is situated in the wider Nitaskinan region, the traditional homeland of the Atikamekw Indigenous Peoples.</p>
          <p>The Bohême Système campground is surrounded by forest with access to a beach and lake, among a rural community with a depanneur/other amenities nearby. </p>
          <p>Respect for the environment and the regional neighbours is a must!</p>
          </div>
          <div class="column opac2">
            <img id="shroom-img" src="shroom.jpg"/>
          </div>
          </div>

          </div>

        
          </div>

          <div class="tinted-background" id="contribution">
          <img src="fiire.jpg" class="imgbanner"/>
          <h2>Come to Contribute Not to Consume</h2>
          <hr/>
          <p>We hope that those who come to Bohême Système not view themselves solely as participants in a festival, but as contributors to a community-driven phenomenon. We ask all who make the weekend pilgrimage to please…</p>
          <ul>
          <li><a href="https://discord.com/invite/aTHknGAa6S" target='_blank'>
            Join the Discord channel</a> to stay up-to-date on all future communication (it’ll all be there!)
    <ul><li>& please add at least 1 custom emoji reaction to the channel 😏</li></ul></li>
            <li>Be active enough on Discord to coordinate and communicate with your fellow attendees for things such as rides, sleeping arrangements, volunteer shifts, etc.</li>
            <li>Commit a certain amount of energy towards helping make the weekend possible. This can be done in any number of the following ways:
            <ul><li>Volunteering for volunteer shifts (cooking, cleaning, setup, teardown, safer spaces, audio tech, general helper) </li>
            <li>Bringing something with the intention of sharing it with those around you! Edible? Experiential? Visual? Pedagogical? (Not allowed: Microbial)</li>
            <li>Being extra vigilant and mindful of the people, the infrastructure, the land, and ensuring that these things are all being respected and taken care of 💖</li>
            <li>Paying more for your ticket if you have the means to do so</li>
            <li>Leading a daytime activity or workshop during the weekend</li>
            </ul></li>
          </ul>
          

          <p>We’re always open to ideas / initiatives of others! If you have a specific idea for something that you’d like to organize, such as a dedicated chill zone, a special workshop/activity, an audiovisual installation, or anything else… feel free to contact to organizers to start planning it in advance so we can best accommodate it at the festival :)</p>
          <p>Most of the actual camp setup will happen on Wednesday and Thursday leading up to the festival. If you’re interested in coming up early to help build the stage / make your own preparations that require more time, please join the #early-arrivals channel in Discord.</p>

          <p>See u soon!</p>

          <p>Xoxo,</p>

          <p>Nimon & Sicolas</p>

          </div>

          
      

         




  
    


    <div class="tinted-background" id="form">
    <div id="signup">
          <h2>Signing Up</h2>
          <hr/>
          <p>We have a limit of 150 people who can attend Bohême Système. To confirm your attendance, please:</p>

          <ol>
          <li>
            Register and send your camp donation below via Zeffy.
            <ol type="a">
              <li>The fee this year is a sliding scale with a minimum of $100, suggested donation $150+.</li>
            <li>Food is included in this price (4 cooked meals and an assortment of snacks), as well as your campsite or cabin.</li>
              
              </ol>
              </li>

          <li><a href="https://discord.com/invite/aTHknGAa6S" target='_blank'>
            Join the Discord channel</a></li>
          <li>Préparez-vous 😈</li>
          </ol>
          <div class="note">WE ARE NOW AT CAPACITY WITH REGISTRATIONS. WE INVITE YOU TO SIGN UP ON THE WAITLIST BELOW, AND WE WILL CONTACT YOU IF A SPOT BECOMES AVAILABLE.
          </div>
          <a class="button" href="https://forms.gle/pyb4TUZbgUPLnDSu7" target="_blank">Waitlist</a>
          <p>Share this website with those that you trust / love (admire?) as you wish :)<br/><br/>If you & your crew are coming for the first time and don’t have mutual connections to the event, please reach out! <br/><br/>Due to the limited capacity, we reserve the right to cancel & refund your ticket if a) We don’t know you and b) You don’t make an effort to introduce yourself / participate leading up to the festival.</p>
          </div>
    
    </div>
    
    <div class="tinted-background" id="schedule">
    <h2>Weekend Schedule</h2>
          <hr/>

          <img src="bssched.png"/>

    </div>
    </div>
</body>





</html>




  );
}

export default Home;
